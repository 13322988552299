import React, { Component } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles(theme => ({
  topbar: {
    minHeight: "45px",
  },
  title: {
    marginRight: "auto",
    marginLeft: "auto",
    height: 35
  }
}));

const UserAccessTopBarComponent = props => {
  const redirectToHome = props.redirectToHome;
  const properties = props.props;
  const storeInfo = props.storeInfo
  const classes = useStyles();

  return (
    <AppBar position="fixed" elevation={2}>
      <Toolbar className={classes.topbar}>
        <img
          alt=""
          src={storeInfo && storeInfo.logoURL? storeInfo.logoURL : require("../../assets/topbar/logo.svg").default}
          className={classes.title}
          onClick={() => {
            redirectToHome(properties);
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

class UserAccessTopBar extends Component {
  constructor(props) {
    super(props);
    this.redirectToHome.bind(this);
  }

  redirectToHome(props) {
    let path = "/";
    props.props.history.push(path);
  }

  render() {
    return (
      <UserAccessTopBarComponent
        storeInfo={this.props.storeInfo}
        props={this.props}
        redirectToHome={this.redirectToHome}
      />
    );
  }
}

export default connect(null, null)(UserAccessTopBar);