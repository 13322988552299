import Session from "../../utils/Session";
import { REQUEST_DEVICE_ACCESS, DELETE_DEVICE_ACCESS } from "./actionTypes";
import { BASE_URL } from '../../utils/Network';


export const getDeviceAccess = (deviceSerialNumber, handleAPIOnError) => {
  return function(dispatch) {

    const action = {
      type: REQUEST_DEVICE_ACCESS,
      data: {
        isLoading: true,
      }
    };
    dispatch(action);

    const apiUrl = BASE_URL + '/api/v1/access/getDeviceAccessList';
    const headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'auth-token': Session.getToken(),
      'deviceSerialNumber': deviceSerialNumber
    };

    return fetch(apiUrl, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      // body data type must match "Content-Type" header
    }).then(response => response.json())
      .then(data => {
        if (data.error) {
          action.data.accessList = [];
          handleAPIOnError(data.error)
        } else {
          //success
          action.data.accessList = data;
        }

        action.data.isLoading = false;
        dispatch(action);
      })
      .catch( (err) => {
        //hadle error
        handleAPIOnError(err)
        action.data.accessList = [];
        action.data.isLoading = false;
        dispatch(action);
    });
  }
};

export const removeDeviceAccess = (deviceSerialNumber, access, handleAPISuccess, handleAPIOnError) => {
  return function(dispatch) {
    const action = {
      type: DELETE_DEVICE_ACCESS,
      data: {
        isLoading: true,
      }
    };
    dispatch(action);

    const apiUrl = BASE_URL + '/api/v1/access/removeDeviceAccess';
    const headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'auth-token': Session.getToken(),
      'deviceSerialNumber': deviceSerialNumber
    };

    const body = JSON.stringify({accessUID: access._id});
    
    return fetch(apiUrl, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      body: body,
      // body data type must match "Content-Type" header
    }).then(response => response.json())
      .then(data => {

        if (data.error) {
          handleAPIOnError(data.error)
        } else {
          //success
          handleAPISuccess(data)
        }

        action.data.isLoading = false;
        dispatch(action);
      })
      .catch( (err) => {
        //hadle error
        handleAPIOnError(err)
        action.data.isLoading = false;
        dispatch(action);
    });
  }
};