import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Session from "../../utils/Session";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import DeviceItemListView from "../../components/items/DeviceItemListView";

import { getOwnerDevices, sendDeviceAction } from "./actions";
import { NAME } from "./constants";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    height: "100vh",
    width: "100%"
  },
  title: {
    textTransform: "capitalize",
    color: "#2D2D2D",
    fontWeight: "bold",
    fontSize: "16pt",
    top: "50%",
    textAlign: "center",
    padding: "0px!important",
    marginBottom: 20,
    marginTop: 10,
    fontFamily: "inherit"
  },
  accessList: {
    marginTop: 30
  },
  spinnerStyle: {
    color: theme.palette.spinner.color
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 30,
    marginTop: 5,
    marginBottom: 5
  }
}));

const DashboardDevicesSection = props => {
  const classes = useStyles();
  const { history, list, deviceSerialLoading, isLoading } = props;
  const { sendDeviceAction, handleKeysClick, handleActivityClick } = props;

  function handleItemClick(e, device) {
    e.stopPropagation();
  }

  function handleOpenDoor(e, device) {
    e.stopPropagation();
    sendDeviceAction(device, handleAPISuccess, handleAPIOnError);
  }

  function handleAPIOnError(error) {
    alert(error);
    Session.disposeSession();
    window.location.href = "/login";
  }

  function handleAPISuccess(data) {}

  return (
    <div className={classes.container}>
      <div className={classes.items}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Typography className={classes.title} gutterBottom component="h2">
              My devices
            </Typography>
          </Grid>
        </Grid>
      </div>

      {isLoading
        ? <div className={classes.spinnerContainer}>
            <CircularProgress className={classes.spinnerStyle} />
          </div>
        : <DeviceItemListView
            list={list}
            handleItemClick={handleItemClick}
            handleOpenDoor={handleOpenDoor}
            handleKeysClick={handleKeysClick}
            handleActivityClick={handleActivityClick}
            history={history}
            deviceSerialLoading={deviceSerialLoading}
          />}
    </div>
  );
};

class DashboardDevices extends Component {
  constructor(props) {
    super(props);
    this.handleOnLogin.bind(this);
    this.handleKeysClick.bind(this);
    this.handleActivityClick.bind(this);
  }

  componentDidMount() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props);
    } else {
      this.props.getOwnerDevices(this.handleAPIOnError);
    }
  }

  componentDidUpdate() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props);
    }
  }

  handleOnLogin(props) {
    let path = "/login";
    this.props.history.push(path);
  }

  handleKeysClick(e, device, history) {
    let path = "/dashboard/access/key";
    history.push(path, device);
  }

  handleActivityClick(e, device, history) {
    let path = "/dashboard/access/activity";
    history.push(path, device);
  }

  handleAPIOnError(error) {
    alert(error);
    Session.disposeSession();
    window.location.href = "/login";
  }

  render() {
    return (
      <DashboardDevicesSection
        list={this.props.devicesList}
        sendDeviceAction={this.props.sendDeviceAction}
        handleKeysClick={this.handleKeysClick}
        handleActivityClick={this.handleActivityClick}
        history={this.props.history}
        isLoading={this.props.isLoading}
        deviceSerialLoading={this.props.deviceSerialLoading}
      />
    );
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    devicesList: state[NAME].devicesList,
    isLoading: state[NAME].isLoading,
    deviceSerialLoading: state[NAME].deviceSerialLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOwnerDevices: handleAPIOnError =>
      dispatch(getOwnerDevices(handleAPIOnError)),
    sendDeviceAction: (device, handleAPISuccess, handleAPIOnError) =>
      dispatch(sendDeviceAction(device, handleAPISuccess, handleAPIOnError))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDevices);
