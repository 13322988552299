import Session from "../../utils/Session";
import { CREATE_DEVICE_ACCESS } from "./actionTypes";
import { BASE_URL } from '../../utils/Network';


export const createDeviceAccess = (deviceSerialNumber, access, handleAPISuccess, handleAPIOnError) => {
  return function(dispatch) {
    const action = {
      type: CREATE_DEVICE_ACCESS,
      data: {
        isLoading: true,
      }
    };
    dispatch(action);

    const apiUrl = BASE_URL + '/api/v1/access/createDeviceAccess';
    const headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'auth-token': Session.getToken(),
      'deviceSerialNumber': deviceSerialNumber
    };

    const body = JSON.stringify(access);
    
    return fetch(apiUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      body: body,
      // body data type must match "Content-Type" header
    }).then(response => response.json())
      .then(data => {

        console.log('accessList', data)
        if (data.error) {
          handleAPIOnError(data.error)
        } else {
          //success
          handleAPISuccess(data);
        }

        action.data.isLoading = false;
        dispatch(action);
      })
      .catch( (err) => {
        //hadle error
        handleAPIOnError(err)
        action.data.isLoading = false;
        dispatch(action);
        
    });
  }
};
