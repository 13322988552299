import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Session from "../../utils/Session";

import ActivityItemListView from "../../components/items/ActivityItemListView";


import { NAME } from "./constants";
import { getDeviceActivity } from "./actions";


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    height: "100vh",
    width: "100%"
  },
  title: {
    textTransform: "capitalize",
    color: "#2D2D2D",
    fontWeight: "bold",
    fontSize: "16pt",
    top: "50%",
    textAlign: "center",
    padding: "0px!important",
    marginBottom: 20,
    marginTop: 10,
    fontFamily: "inherit"
  },
  activityList: {
    marginTop: 30
  },
  spinnerStyle: {
    color: theme.palette.spinner.color
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 30,
    marginTop: 5,
    marginBottom: 5
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  back: {
    position: "absolute",
    marginLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20px"
    }
  },
}));

const DashboardActivitySection = props => {
  const classes = useStyles();
  const {
    list,
    isLoading,
    history,
    device,
    getDeviceActivity
  } = props;

  function handleBack() {
    history.goBack();
  }

  function handleAPIOnError(error) {
    alert(error);
  }

  function handleAPISuccess(data) {}

  return (
    <div className={classes.container}>
      <div className={classes.items}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Fab
              aria-label="back"
              className={classes.back}
              color="secondary"
              size="small"
              onClick={() => handleBack()}
            >
              <ArrowBackIcon />
            </Fab>
            <Typography className={classes.title} gutterBottom component="h2">
              Device activity
            </Typography>
          </Grid>
        </Grid>
      </div>

      {isLoading
        ? <div className={classes.spinnerContainer}>
            <CircularProgress className={classes.spinnerStyle} />
          </div>
        : <ActivityItemListView
        clasName={classes.activityList}
        list={list}
        device={device}
        isLoading={isLoading}></ActivityItemListView>
        }
    </div>
  );
};

class DashboardActivity extends Component {
  constructor(props) {
    super(props);
    this.handleOnLogin.bind(this);
  }

  componentDidMount() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props);
    } else {
      const device = this.props.location.state;
      console.log("device: ", device);
      this.props.getDeviceActivity(device.deviceSerialNumber, this.handleAPIOnError);
    }
  }

  handleOnLogin(props) {
    let path = "/login";
    props.history.push(path);
  }

  handleAPIOnError(error) {
    alert(error)
    Session.disposeSession();
    window.location.href = "/login";
  }

  render() {
    return (
      <DashboardActivitySection 
      list={this.props.activityList}
      device={this.props.location.state}
      isLoading={this.props.isLoading}
      history={this.props.history}>
        
      </DashboardActivitySection>
    );
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    activityList: state[NAME].activityList,
    isLoading: state[NAME].isLoading,
    error: state[NAME].error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDeviceActivity: (serialNumber, handleAPIOnError) => dispatch(getDeviceActivity(serialNumber, handleAPIOnError)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardActivity);
