import { LOGIN } from "./actionTypes";
import Session from '../../utils/Session'
import { BASE_URL } from '../../utils/Network';

export const signIn = (credentials) => {
  return function(dispatch) {

    const action = {
      type: LOGIN,
      data: {
        user: null,
        auth: false,
        error: null,
        isLoading: true,
      }
    };
    dispatch(action);

    const apiUrl = BASE_URL + '/api/v1/user/login';
    const headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    };


    const body = JSON.stringify({ email: credentials.email, password : credentials.password });
    return fetch(apiUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      body: body,
      // body data type must match "Content-Type" header
    }).then(response => response.json())
      .then(data => {
        if (data.error) {
          action.data.error = data.error;
          action.data.auth = false;
        } else {
          //success
          //store token
          Session.updateTokens(data.token, data.refreshToken);
          action.data.user = {
            displayName: data.userDisplayName,
            userEmail: data.userEmail,
            since: data.since
          };
          action.data.auth = true;
          action.data.error = null;
        }

        action.data.isLoading = false;
        dispatch(action);
      })
      .catch( (err) => {
        //hadle error
        Session.disposeSession();
        action.data.auth = false;
        action.data.error = err;
        action.data.isLoading = false;
        dispatch(action);
    });
  }
};


export const signOut = () => {
  return (dispatch) => {

    Session.disposeSession();

    const action = {
      type: LOGIN,
      data: {
        auth: false,
        isLoading: false,
        error: null
      }
    };
    dispatch(action);
  };
};
