// VENDOR
import { combineReducers } from 'redux';

import { NAME as HOME_NAME } from '../../containers/Home/constants';
import  homeReducer from '../../containers/Home/reducer';

import { NAME as LOGIN_NAME } from '../../containers/Login/constants';
import  authReducer from '../../containers/Login/reducer';

import { NAME as KEY_NAME } from '../../containers/KeyAccess/constants';
import  keyAccessReducer from '../../containers/KeyAccess/reducer';

import { NAME as KEY_DASHBOARD_DEVICES } from '../../containers/DashboardDevices/constants';
import  devicesReducer from '../../containers/DashboardDevices/reducer';

import { NAME as KEY_DASHBOARD_DEVICE_KEYS } from '../../containers/DashboardDeviceKeys/constants';
import  deviceKeysReducer from '../../containers/DashboardDeviceKeys/reducer';

import { NAME as KEY_DASHBOARD_CREATE_ACCESS } from '../../containers/DashboardCreateAccess/constants';
import  createAccessReducer from '../../containers/DashboardCreateAccess/reducer';

import { NAME as KEY_DASHBOARD_DEVICE_ACTIVITY } from '../../containers/DashboardActivity/constants';
import  deviceActivityReducer from '../../containers/DashboardActivity/reducer';

import { NAME as DOOR_BELL } from '../../containers/DoorBell/constants';
import  doorBellReducer from '../../containers/DoorBell/reducer';

const rootReducer = combineReducers({
  [DOOR_BELL]: doorBellReducer,
  [KEY_DASHBOARD_DEVICE_ACTIVITY]: deviceActivityReducer,
  [KEY_DASHBOARD_CREATE_ACCESS]: createAccessReducer,
  [KEY_DASHBOARD_DEVICE_KEYS]: deviceKeysReducer,
  [KEY_DASHBOARD_DEVICES]: devicesReducer,
  [KEY_NAME]: keyAccessReducer,
  [HOME_NAME]: homeReducer,
  [LOGIN_NAME]: authReducer,
});

export default rootReducer;