import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TopBar from "./TopBar";

import SideBar from "../sideMenu/SideBar";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1
  },
  sideBar: {
    marginTop: 65
  }
}));

const TopBarHome = props => {
  const { handleDrawerOpenCallback, matchesMD, user, history } = props;
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  
  function handleDrawerOpen() {
    setOpenDrawer(true);
    handleDrawerOpenCallback(true);
  }

  function handleDrawerClose() {
    setOpenDrawer(false);
    handleDrawerOpenCallback(false);
  }

  return (    
    <div className={classes.root}>
      <TopBar props={props.props} handleDrawerOpen={handleDrawerOpen}>
        {" "}
      </TopBar>
      <SideBar
        className={classes.sideBar}
        user={props.user}
        open={openDrawer}
        handleClose={handleDrawerClose}
        matchesMD={matchesMD}
        props={props.props}
        history={history}
      />
    </div>
  );
};

export default TopBarHome;
