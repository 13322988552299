import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import Session from '../../utils/Session'
import { signIn, signOut } from "./actions";
import { NAME } from "./constants";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    width: "100%",
    paddingTop: 60,
   
  },
  container: {
    position: "relative"
  },
  section: {
    flexGrow: 1,
    position: "absolute",
    left: "0",
    top: "20%"
  },
  card: {
    margin: 10,
    borderRadius: "5px",
    background: "#ffffff"
  },
  title: {
    maxHeight: "50px",
    margin: "30px"
  },
  textContainer: {
    paddingLeft: 20,
    paddingRight: 20
  },
  textField: {
    textAlign: "center",
    marginTop: 20,
    width: "100%",
    background: '#fafafc',
  },
  loginButton: {
    textTransform: "capitalize!important",
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 25,
    marginRight: 25,
    width: "100%",
    height: 50,
    backgroundColor: theme.palette.button.background,
    color: theme.palette.button.text,
    fontSize: 18,
    fontWeight: "bold",
    "&:hover": {
      background: theme.palette.button.text,
      color: '#ffffff'
    }
  },
  cssFocused: {
    color: "#7f404042!imporntat"
  },
  cssLabel: {
    color: "#7f404042!important"
  },
  cssOutlinedInput: {
    color: "#404042!important",
    borderColor: "#fafafc!important"
  },
  notchedOutline: {
    color: "#7f404042!important",
    borderColor: "#fafafc!important"
  },
  spinnerStyle: {
    color: theme.palette.spinner.color
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50,
    marginTop: 30,
    marginBottom: 25
  }
}));

var states = {
  email: "",
  password: ""
};

const LoginSection = props => {
  const { error, isLoading, auth } = props.props;
  const [isButtonTapped, setButtonTapped] = React.useState(false);

  const classes = useStyles();

  function buttonClickListener() {
    setButtonTapped(true);
    props.props.signIn(states);
  }

  function handleChange(e) {
    if (states.hasOwnProperty(e.target.id)) {
      states[e.target.id] = e.target.value;
    }
  }


  if (isButtonTapped && !isLoading && auth) {
    document.getElementById('email').value = ""
    document.getElementById('password').value = ""
    states['email'] = ""
    states['password'] = "";
    setButtonTapped(false)
  }

  if (error) {
    alert(error);
  }

  return (
    <div className={classes.root}>
      <Grid container justify="center" className={classes.section}>
        <Grid item md={4} xs={12}>
          <form>
            <Card className={classes.card}>
              <CardContent>
                <img
                  className={classes.title}
                  alt=""
                  src={require('../../assets/topbar/logo.svg').default}
                />
                <div className={classes.textContainer}>
                  <TextField
                    required
                    id="email"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    defaultValue=""
                    className={classes.textField}
                    variant="outlined"
                    onChange={e => {
                      handleChange(e);
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                  />
                </div>
                <div className={classes.textContainer}>
                  <TextField
                    required
                    id="password"
                    label="Password"
                    type="password"
                    autoComplete="password"
                    className={classes.textField}
                    variant="outlined"
                    onChange={e => {
                      handleChange(e);
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                  />
                </div>
              </CardContent>
              {isLoading
                ? <div className={classes.spinnerContainer}>
                    <CircularProgress className={classes.spinnerStyle} />
                  </div>
                : <div>
                    <CardActions>
                      <Button
                        className={classes.loginButton}
                        onClick={buttonClickListener}
                      >
                        Iniciar Sesión
                      </Button>
                    </CardActions>
                  </div>}
            </Card>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

class Login extends Component {

  componentWillMount() {
    //set page background
    document.body.style.background = "#fafafc";
  }

  componentDidMount() {
    if (Session.getToken()) {
      this.redirectToDashboard()
    }
  }

  componentDidUpdate() {
    if (Session.getToken()) {
      this.redirectToDashboard()
    }
  }

  redirectToDashboard() {
    let path = "/dashboard/access";
    this.props.history.push(path);
  }

  render() {
    return <LoginSection props={this.props} />;
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    auth: state[NAME].auth,
    isLoading: state[NAME].isLoading,
    error: state[NAME].error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: creds => dispatch(signIn(creds)),
    signOut: () => dispatch(signOut()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
