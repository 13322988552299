import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

import Home from "../containers/Home";
import Login from "../containers/Login";
import KeyAccess from "../containers/KeyAccess";
import DoorBell from "../containers/DoorBell";

function MainContainer(props) {
  return (
    <div>
      <Route path="/dashboard" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/key" component={KeyAccess} />
      <Route path="/qr" component={DoorBell} />
      <Route exact path="/" component={Login} />
    </div>
  );
}

export default connect(null)(MainContainer);
