import React from 'react';
import './App.css';

import { BrowserRouter as Router } from "react-router-dom";
import MainContainer from './components/MainContainer'
import store from './providers/redux/store'
import { Provider } from 'react-redux'

import { customTheme } from './providers/theme/customTheme'
import  ScrollToTop  from './components/ScrollToTop'

import { ThemeProvider } from "@material-ui/styles";

function App() {

  return (
    <ThemeProvider theme={customTheme}>
     <Provider store={store}>
      <div className="Cittyo">
        <Router>
        <ScrollToTop>
          <MainContainer></MainContainer>
          </ScrollToTop>
        </Router>
      </div>
    </Provider>
    </ThemeProvider>
  );
}

export default App;

