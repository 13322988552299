// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { REQUEST_DEVICES, SEND_DEVICE_ACTION } from "./actionTypes";
import { NAME } from "./constants";

const initialState = {
  devicesList: [],
  response: null,
  isLoading: false,
  error: null, 
};

const devicesReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === "persist/REHYDRATE" && action.payload) {
    state = set(state, "accessList", action.payload[NAME].devicesList);
  }

  if (type === REQUEST_DEVICES) {
    state = set(state, "devicesList", data.devicesList || []);
    state = set(state, "isLoading", data.isLoading || false);
  }

  if (type === SEND_DEVICE_ACTION) {
    state = set(state, "deviceSerialLoading", data.deviceSerialLoading || '');
  }
  return state;
};

export default devicesReducer;