import React, { Component } from "react";
import { connect } from "react-redux";

import TopBarHome from "../../components/topbar/TopBarHome";
import DashboardContainer from "../../components/dashboard/DashboardContainer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { NAME as LOGIN_NAME } from '../Login/constants'

import Session from "../../utils/Session"


const HomeSection = props => {
  const matchesMD = useMediaQuery("(min-width:600px)");
  const { history } = props
  
  function handleDrawerOpenCallback(isDrawerOpen) {
    console.log("handleDrawerOpenCallback");
  }

  return (
    <React.Fragment>
      <TopBarHome
        user={props.user}
        handleDrawerOpenCallback={handleDrawerOpenCallback}
        matchesMD={matchesMD}
        history={history}
      />
      <DashboardContainer matchesMD={matchesMD} user={props.user}/>
    </React.Fragment>
  );
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.handleOnLogin.bind(this);
  }

  componentDidMount() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props)
    }
  }

  componentDidUpdate() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props)
    }
  }

  handleOnLogin(props) {
    let path = "/login";
    props.history.push(path);
  }

   // This function creates a dinamyc manifest.json that allows a PWA app.
  // When the app is saved into the desktop on a mobile device it use this new manifest.json
  // to create teh PWA app using the Key start URL
  createManifest() {
    console.log("Create manifest")
    var myDynamicManifest = {
      "short_name": "Cittyo control",
      "name": "Cittyo smart access control.",
      "icons": [
        {
          "src": "icon.png",
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        },
        {
          "src": "logo192.png",
          "type": "image/png",
          "sizes": "192x192"
        },
        {
          "src": "logo512.png",
          "type": "image/png",
          "sizes": "512x512"
        }
      ],
      "start_url": '/login',
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff"
    }
    
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
      console.log('link created')
  }

  render() {
    return (
      <HomeSection user={this.props.user} history={this.props.history}/>
    );
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    user: state[LOGIN_NAME].user,
    auth: state[LOGIN_NAME].auth,
    isLoading: state[LOGIN_NAME].isLoading,
    error: state[LOGIN_NAME].error,
  };
}

export default connect(mapStateToProps, null)(Home);
