
const TOKEN_KEY = 'TK'
const REFRESH_TOKEN_KEY = 'RTK'
const ACCESS_CODE_KEY = 'ACK'



class Session {

    static updateTokens(token, refreshToken) {
        sessionStorage.setItem(TOKEN_KEY, token);
        sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }

    static saveToken(token) {
        sessionStorage.setItem(TOKEN_KEY, token);
    }
    
    static getToken() {
        return sessionStorage.getItem(TOKEN_KEY);
    }
    
    static deleteToken() {
        sessionStorage.removeItem(TOKEN_KEY);
    }

    static disposeSession() {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    static getAccessCode() {
        return sessionStorage.getItem(ACCESS_CODE_KEY);
    }

    static setAccessCode(accessCode) {
        sessionStorage.setItem(ACCESS_CODE_KEY, accessCode);
    }

    static clearAccessCode() {
        sessionStorage.removeItem(ACCESS_CODE_KEY);
    }
}

export default Session;
