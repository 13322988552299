import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  gridCard: {
    borderRadius: 1,
    margin: "2px",
    padding: "10px!important"
  },
  cardContent: {
    textTransform: "none",
    color: "#424143",
    fontSize: "10pt",
    marginLeft: "10px"
  },
  cardTitle: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "10pt",
    marginLeft: 10,
    marginBottom: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  cardItemTitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "9pt",
    marginLeft: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  itemText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#000000",
    fontSize: "9pt",
    marginLeft: 10,
    marginRight: 10
  },
  centerGrid: {
    alignSelf: "center"
  },
  spinnerStyle: {
    color: theme.palette.spinner.color
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 30,
    marginTop: 5,
    marginBottom: 5
  },
  buttonContainer: {
    alignSelf: "center",
    alignItems: "center"
  },
  iconButton: {
    height: 30,
    width: 30
  }
}));

const ActivityItem = props => {
  var { device, isLoading, activity } = props;
  const classes = useStyles();

  const serial = device.deviceSerialNumber;

  const date = parseInt(activity.date);
  const dateObject = new Date(date);

  const getTime = timeInMinutes => {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = (timeInMinutes / 60 - hours) * 60;
    const time =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0");
    return time;
  };

  const getDate = dateInmseconds => {
    const date = dateInmseconds * 1000;
    const newDate = new Date(date);
    const time =
      (newDate.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      newDate.getDate().toString().padStart(2, "0") +
      "/" +
      newDate.getFullYear().toString();
    return time;
  };

  const getActivityTitle = activity => {
    if (activity.type === "ACTION") {
      return activity.eventType;
    }

    if (activity.type === "KEY") {
      if (activity.eventType === "CREATE") {
        return "KEY CREATED";
      }
      if (activity.eventType === "DELETE") {
        return "KEY DELETED";
      }
      return activity.type + " " + activity.eventType;
    }
    return "Unknown";
  };

  const getActivityViewFromType = activity => {
    if (activity.type === "ACTION") {
      return getActionView(activity);
    }
    if (activity.type === "KEY") {
      return getKeyView(activity);
    }
    return <div />;
  };

  const getKeyView = activity => {
    return (
      <Grid container>
        <Grid container>
          <Grid item xs={4} sm={3} className={classes.centerGrid}>
            <Typography className={classes.cardItemTitle} component="h2">
              {activity.eventType === "CREATE" ? "Created by:" : "Deleted by:"}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} className={classes.centerGrid}>
            <Typography className={classes.itemText} component="h2">
              {activity.author ? activity.author.email : "Unknown"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} sm={3} className={classes.centerGrid}>
            <Typography className={classes.cardItemTitle} component="h2">
              Key guest name:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} className={classes.centerGrid}>
            <Typography className={classes.itemText} component="h2">
              {activity.key ? activity.key.guestName : "Unknown"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} sm={3} className={classes.centerGrid}>
            <Typography className={classes.cardItemTitle} component="h2">
              Key description:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} className={classes.centerGrid}>
            <Typography className={classes.itemText} component="h2">
              {activity.key ? activity.key.accessDescription : "Unknown"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justiy="center">
          <Grid item xs={4} sm={3} className={classes.centerGrid}>
            <Typography className={classes.cardItemTitle} component="h2">
              {activity.eventType === "CREATE"
                ? "Created date:"
                : "Deleted date:"}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} className={classes.centerGrid}>
            <Typography className={classes.itemText} component="h2">
              {dateObject.toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getActionView = activity => {
    return (
      <Grid container>
        <Grid container>
          <Grid item xs={4} sm={3} className={classes.centerGrid}>
            <Typography className={classes.cardItemTitle} component="h2">
              Executed from:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} className={classes.centerGrid}>
            <Typography className={classes.itemText} component="h2">
              {activity.author
                ? "User account"
                : activity.key ? "Link Key" : "Unknown"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} sm={3} className={classes.centerGrid}>
            <Typography className={classes.cardItemTitle} component="h2">
              Executed by:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} className={classes.centerGrid}>
            <Typography className={classes.itemText} component="h2">
              {activity.author
                ? activity.author.email
                : activity.key ? activity.key.guestName : "Unknown"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justiy="center">
          <Grid item xs={4} sm={3} className={classes.centerGrid}>
            <Typography className={classes.cardItemTitle} component="h2">
              Created at:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} className={classes.centerGrid}>
            <Typography className={classes.itemText} component="h2">
              {dateObject.toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item xs={11} sm={10}>
        <Card className={classes.gridCard}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} className={classes.centerGrid}>
                  <Typography className={classes.cardTitle} component="h2">
                    {getActivityTitle(activity)}
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              {getActivityViewFromType(activity)}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

class ActivityItemListView extends Component {
  render() {
    const { list, device, isLoading } = this.props;

    return (
      <div>
        {list === undefined || list.length <= 0
          ? <div />
          : list.map((value, index) =>
              <ActivityItem
                style={{ padding: "0!important" }}
                key={`item-${index}`}
                index={index}
                device={device}
                activity={value}
              />
            )}
      </div>
    );
  }
}

export default ActivityItemListView;
