import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
  avatar: {
    width: "50px!important",
    height: "50px!important",
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    borderRadius: "50%",
    borderColor: "rgba(65,65,65,0.1)",
    borderWidth: "5px",
    borderStyle: "solid"
  },
  container: {
    paddingTop: "50px"
  },
  name: {
    padding: "0px!important",
    margin: "0px!important",
    marginTop: "15px!important",
    color: theme.palette.primary.main,
    textAlign: "center",
    fontSize: "12pt",
    fontWeight: "bold"
  },
  email: {
    padding: "0px!important",
    marginTop: "10px!important",
    color: theme.palette.primary.main,
    textAlign: "center",
    fontSize: "11pt",
    marginLeft: "20px",
    marginRight: "20px"
  },
  divider: {
    backgroundColor: "white",
    height: "1px",
    marginLeft: "50px",
    marginRight: "50px",
    marginBottom: "30px",
    marginTop: "40px!important"
  }
}));

export default function ProfileMenu(props) {
  var { user } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Avatar
        alt="Default Avatar"
        className={classes.avatar}
        src={
          user && user.pictureUrl
            ? user.pictureUrl
            : require("../../assets/topbar/ic_account.svg").default
        }
        onError={e => {
          e.target.src = require("../../assets/topbar/ic_account.svg").default; // some replacement image
        }}
      />
      <p className={classes.name}>
        {user && user.displayName ? user.displayName : ""}
      </p>
      <p className={classes.email}>
        {user && user.userEmail ? user.userEmail : ""}
      </p>
      <Divider className={classes.divider} />
    </div>
  );
}
