// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { LOGIN } from "./actionTypes";
// CONSTANTS
import { NAME } from "./constants";

const initialState = {
  user: null,
  auth: false,
  isLoading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === "persist/REHYDRATE" && action.payload) {
    state = set(state, "auth", action.payload[NAME].auth);
    state = set(state, "user", action.payload[NAME].user);
  }

  if (type === LOGIN) {
    console.log(data)
    state = set(state, "user", data.user || null);
    state = set(state, "auth", data.auth);
    state = set(state,"error", data.error || null);
    state = set(state, "isLoading", data.isLoading);
  }

  return state;
};

export default authReducer;