import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import WorkerAccess from "./WorkerAccess";
import GuestAccess from "./GuestAccess";
import Divider from "@material-ui/core/Divider";
import FreePassAccess from "./FreePassAccess";
import DoorBellAccess from "./DoorBellAccess";

//List of
const enumAccessTypes = [
  {
    name: "Free pass",
    type: "free_pass",
    id: 10
  },
  {
    name: "Worker",
    type: "worker",
    id: 20
  },
  {
    name: "Guest",
    type: "guest",
    id: 30
  }
  // {
  //   name: "Doorbell",
  //   type: "door_bell",
  //   id: 40
  // }
];

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: 10,
    width: "100%"
  },
  divider: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
    marginBottom: 20
  }
}));

export default function AccessTypeSelector({ handleAccessUpdated }) {
  const classes = useStyles();
  const [accessType, setAccessType] = React.useState(enumAccessTypes[0].id);
  const [access, setAccess] = React.useState({
    type: enumAccessTypes[0].type.toUpperCase()
  });

  // Update access
  React.useEffect(
    () => {
      handleAccessUpdated(access);
    },
    [access]
  );

  const handleChange = event => {
    setAccessType(event.target.value);

    for (var i = 0; i <= enumAccessTypes.length - 1; i++) {
      if (enumAccessTypes[i].id === event.target.value) {
        setAccess({
          type: enumAccessTypes[i].type.toUpperCase()
        })
        break
      }
    }
  };

  const handleWorkerAccessUpdated = access => {
    console.log("Worker access updated: ", access);
    setAccess(access);
  };

  const handleGuestAccessUpdated = access => {
    console.log("Guest access updated: ", access);
    setAccess(access);
  };

  const buildAccessUIBasedOnType = accessType => {
    // For free pass
    if (enumAccessTypes.length >= 1 && accessType === enumAccessTypes[0].id) {
      return <FreePassAccess />;
    }

    if (enumAccessTypes.length >= 2 && accessType === enumAccessTypes[1].id) {
      return (
        <WorkerAccess handleWorkerAccessUpdated={handleWorkerAccessUpdated} />
      );
    }

    if (enumAccessTypes.length >= 3 && accessType === enumAccessTypes[2].id) {
      return (
        <GuestAccess handleGuestAccessUpdated={handleGuestAccessUpdated} />
      );
    }

    // For door bell is the same as the free_pass for now
    if (enumAccessTypes.length >= 4 && accessType === enumAccessTypes[3].id) {
      return <DoorBellAccess />;
    }
  };

  return (
    <div>
      <Divider className={classes.divider} />
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-filled-label" />
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={accessType}
          onChange={handleChange}
        >
          {" "}{enumAccessTypes.map((value, index) =>
            <MenuItem key={value + index} value={value.id}>
              {value.name}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {buildAccessUIBasedOnType(accessType)}
      <Divider className={classes.divider} />
    </div>
  );
}
