import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    marginBottom: 10,
  },
  text: {
      textAlign: 'center'
  }
}));

// This kind of access never expires
export default function FreePassAccess() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
        This kind of access never expires. User can control the access at any
        time.
      </Typography>
    </div>
  );
}
