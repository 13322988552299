import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuContent from "./MenuContent";

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    margin: "20px"
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    border: "none",
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    justifyContent: "flex-end",
    height: 50
  },
  title: {
    height: 30,
    margin: "auto",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  titleFixed: {
    height: 30,
    margin: 10,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export default function PersistentDrawerLeft({
  user,
  open,
  handleClose,
  matchesMD,
  props,
  history
}) {
  const classes = useStyles();
  const theme = useTheme();

  if (matchesMD) {
    open = true;
  }

  return (
    <Drawer
      open={open}
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      {!matchesMD
        ? <div className={classes.drawerHeader}>
            <img
              alt=""
              src={require("../../assets/topbar/logo.svg").default}
              className={classes.title}
            />
            <IconButton onClick={handleClose}>
              {theme.direction === "ltr"
                ? <ChevronLeftIcon />
                : <ChevronRightIcon />}
            </IconButton>
          </div>
        : <img
            alt=""
            src={require("../../assets/topbar/logo.svg").default}
            className={classes.titleFixed}
          />}
      <Divider />

      <MenuContent user={user} props={props} history={history} />
    </Drawer>
  );
}
