// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { REQUEST_DEVICE_ACTIVITY } from "./actionTypes";
import { NAME } from "./constants";

const initialState = {
    activityList: [],
  response: null,
  isLoading: false,
  error: null, 
};

const deviceKeysReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === "persist/REHYDRATE" && action.payload) {
    state = set(state, "activityList", action.payload[NAME].activityList);
  }

  if (type === REQUEST_DEVICE_ACTIVITY) {
    state = set(state, "activityList", data.activityList || []);
    state = set(state, "isLoading", data.isLoading || false);
    state = set(state, "error", data.error || null);
  }

  return state;
};

export default deviceKeysReducer;