import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";

import Session from "../../utils/Session";

import CancelButton from "../../components/buttons/CancelButton";
import ConfirmButton from "../../components/buttons/ConfirmButton";

import { createDeviceAccess } from "./actions";
import { NAME } from "./constants";
import AccessTypeSelector from "../../components/access/AccessTypeSelector";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    height: "100vh",
    width: "100%"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  back: {
    position: "absolute",
    marginLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20px"
    }
  },
  title: {
    textTransform: "capitalize",
    color: "#2D2D2D",
    fontWeight: "bold",
    fontSize: "16pt",
    top: "50%",
    textAlign: "center",
    padding: "0px!important",
    marginBottom: 20,
    marginTop: 10,
    fontFamily: "inherit",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12pt"
    }
  },
  cardContainer: {
    padding: 20
  },
  buttonsContainer: {
    width: "100%",
    marginTop: 20,
    height: 50
  },
  spinnerStyle: {
    color: theme.palette.spinner.color
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  }
}));

const DashboardCreateAccessSection = props => {
  const classes = useStyles();
  const { device, isLoading, history, createDeviceAccess } = props;

  const [accessForm] = React.useState({
    deviceSerialNumber: device.deviceSerialNumber,
    accessCode: "",
    accessType: "link", //link, qr, password, etc.
    accessDescription: "",
    guestName: ""
  });

  const [accessData, setAccessData] = React.useState({});

  function handleBack() {
    history.goBack();
  }

  function handleAPIOnError(error) {
    alert(error);
    Session.disposeSession();
    window.location.href = "/login";
  }

  function handleAPISucess(data) {
    handleBack();
  }

  function handleCreateAccess() {
    console.log("Device: ", device);
    console.log("Access Form: ", accessForm);
    console.log("Access Data: ", accessData);

    //We are mergin the access form information and the access validation
    const newAccess = { ...accessForm, temporaryAccess: accessData };
    console.log("New access: ", newAccess);

    //Create the new access with expiration dates.
    createDeviceAccess(
      device.deviceSerialNumber,
      newAccess,
      handleAPISucess,
      handleAPIOnError
    );
  }

  function handleChangeTextFieldInformation(e) {
    if (accessForm.hasOwnProperty(e.target.id)) {
      accessForm[e.target.id] = e.target.value;
    }
  }

  function handleAccessUpdated(accessUpdate) {
    setAccessData(accessUpdate);
  }

  const serial = device.deviceSerialNumber;
  const deviceName = device.deviceName
    ? device.deviceName.toUpperCase()
    : device.deviceModel + " " + serial.substr(serial.length - 4).toUpperCase();

  return (
    <div className={classes.container}>
      <div className={classes.items}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Fab
              aria-label="back"
              className={classes.back}
              color="secondary"
              size="small"
              onClick={() => handleBack()}
            >
              <ArrowBackIcon />
            </Fab>
            <Typography className={classes.title} gutterBottom component="h2">
              Create Access
            </Typography>
          </Grid>

          <Card className={classes.cardContainer}>
            <Typography className={classes.title} gutterBottom component="h2">
              {deviceName}
            </Typography>
            <TextField
              inputProps={{ maxLength: 50, minlenght: 6 }}
              required
              margin="dense"
              id="guestName"
              label="Guest Name"
              type="text"
              variant="outlined"
              defaultValue={""}
              color="secondary"
              fullWidth
              onChange={handleChangeTextFieldInformation}
            />
            <TextField
              inputProps={{ maxLength: 50, minlenght: 6 }}
              required
              margin="dense"
              id="accessDescription"
              label="Access Description"
              type="text"
              variant="outlined"
              defaultValue={""}
              color="secondary"
              fullWidth
              onChange={handleChangeTextFieldInformation}
            />
            <TextField
              inputProps={{ maxLength: 50, minlenght: 6 }}
              required
              margin="dense"
              id="accessCode"
              label="User access code"
              type="text"
              variant="outlined"
              defaultValue={""}
              color="secondary"
              fullWidth
              onChange={handleChangeTextFieldInformation}
            />
            <AccessTypeSelector handleAccessUpdated={handleAccessUpdated} />
            <div>
              {isLoading
                ? <Grid
                    className={classes.buttonsContainer}
                    container
                    justify="center"
                  >
                    <Grid item xs={12} className={classes.spinnerContainer}>
                      <CircularProgress className={classes.spinnerStyle} />
                    </Grid>
                  </Grid>
                : <Grid
                    className={classes.buttonsContainer}
                    container
                    justify="center"
                  >
                    <Grid item xs={3}>
                      <CancelButton
                        buttonText={"Cancel"}
                        handleOnClick={handleBack}
                      />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                      <ConfirmButton
                        buttonText={"Create"}
                        handleOnClick={handleCreateAccess}
                      />
                    </Grid>
                  </Grid>}
            </div>
          </Card>
        </Grid>
      </div>
    </div>
  );
};

class DashboardCreateAccess extends Component {
  constructor(props) {
    super(props);
    this.handleOnLogin.bind(this);
  }

  componentWillMount() {
    const device = this.props.location.state;
  }

  componentDidMount() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props);
    }
  }

  componentDidUpdate() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props);
    }
  }

  handleOnLogin(props) {
    let path = "/login";
    props.history.push(path);
  }

  render() {
    return (
      <DashboardCreateAccessSection
        device={this.props.location.state}
        isLoading={this.props.isLoading}
        error={this.props.error}
        response={this.props.response}
        history={this.props.history}
        createDeviceAccess={this.props.createDeviceAccess}
      />
    );
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    response: state[NAME].response,
    isLoading: state[NAME].isLoading,
    error: state[NAME].error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDeviceAccess: (
      serialNumber,
      access,
      handleAPISucess,
      handleAPIOnError
    ) =>
      dispatch(
        createDeviceAccess(
          serialNumber,
          access,
          handleAPISucess,
          handleAPIOnError
        )
      )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  DashboardCreateAccess
);
