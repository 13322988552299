// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { CREATE_DEVICE_ACCESS } from "./actionTypes";

import { NAME } from "./constants";


const initialState = {
  response: null,
  isLoading: false,
  error: null, 
};

const createAccessReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === "persist/REHYDRATE" && action.payload) {
    state = set(state, "response", action.payload[NAME].response);
  }

  if (type === CREATE_DEVICE_ACCESS) {
    state = set(state, "response", data.response || null);
    state = set(state, "isLoading", data.isLoading || false);
    state = set(state, "error", data.error || null);
  }

  return state;
};

export default createAccessReducer;