// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { REQUEST_DEVICE_ACCESS, DELETE_DEVICE_ACCESS } from "./actionTypes";
import { NAME } from "./constants";

const initialState = {
  accessList: [],
  response: null,
  isLoading: false,
  error: null, 
};

const deviceKeysReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === "persist/REHYDRATE" && action.payload) {
    state = set(state, "accessList", action.payload[NAME].accessList);
  }

  if (type === REQUEST_DEVICE_ACCESS) {
    state = set(state, "accessList", data.accessList || []);
    state = set(state, "isLoading", data.isLoading || false);
    state = set(state, "error", data.error || null);
  }

  if (type === DELETE_DEVICE_ACCESS) {
    state = set(state, "isLoading", data.isLoading || false);
    state = set(state, "error", data.error || null);
  }

  return state;
};

export default deviceKeysReducer;