import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ToggleDays from "../extras/ToggleDays";

import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { customTheme } from "../../providers/theme/customTheme";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    marginBottom: 10
  }
}));

export const pickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: customTheme.palette.button.text
    }
  }
});

// this kint of access never expires. User needs to removed manually
export default function WorkerAccess({ handleWorkerAccessUpdated }) {
  const classes = useStyles();
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [days, setDays] = React.useState([1, 2, 3, 4, 5]);

  React.useEffect(
    () => {
      const startTimeMinutes = buildHourInMinutes(startTime);
      const endTimeMinutes = buildHourInMinutes(endTime);

      console.log("Start time UTC: ", startTimeMinutes);
      console.log("End time UTC: ", endTimeMinutes);

      if (startTime > endTime) {
        alert("Wrong times selected.");
        setEndTime(startTime);
      } else {
        handleWorkerAccessUpdated({
          type: "WORKER",
          days: days,
          fromTime: startTimeMinutes,
          toTime: endTimeMinutes
        });
      }
    },
    [startTime, endTime, days]
  );

  const handleDaysUpdated = days => {
    setDays(days);
  };

  const buildHourInMinutes = dateTime => {
    return dateTime.getHours() * 60 + dateTime.getMinutes();
  };

  return (
    <div className={classes.container}>
      <ToggleDays handleDaysUpdated={handleDaysUpdated} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={pickerTheme}>
          <Grid container justify="space-around">
            <KeyboardTimePicker
              margin="normal"
              id="from-time-picker"
              label="From:"
              value={startTime}
              onChange={setStartTime}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />

            <KeyboardTimePicker
              margin="normal"
              id="to-time-picker"
              label="To:"
              value={endTime}
              onChange={setEndTime}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />
          </Grid>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}
