import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Typography } from "@material-ui/core";

const DAYS = [
  {
    key: "sunday",
    label: "S"
  },
  {
    key: "monday",
    label: "M"
  },
  {
    key: "tuesday",
    label: "T"
  },
  {
    key: "wednesday",
    label: "W"
  },
  {
    key: "thursday",
    label: "T"
  },
  {
    key: "friday",
    label: "F"
  },
  {
    key: "saturday",
    label: "S"
  }
];

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(1),
    "&:not(:first-child)": {
      border: "1px solid",
      borderColor: theme.palette.button.text,
      borderRadius: "50%"
    },
    "&:first-child": {
      border: "1px solid",
      borderColor: theme.palette.button.text,
      borderRadius: "50%"
    }
  }
}))(ToggleButtonGroup);

const StyledToggle = withStyles(theme => ({
  root: {
    border: "1px solid",
    color: theme.palette.button.text,
    "&$selected": {
      color: "white",
      background: theme.palette.button.text,
      borderColor: theme.palette.button.text
    },
    "&:hover": {
      color: theme.palette.button.text,
      borderColor: theme.palette.button.text,
      background: theme.palette.button.background
    },
    "&:hover$selected": {
      borderColor: theme.palette.button.text,
      background: theme.palette.button.background
    },
    width: 32,
    height: 32
  },
  selected: {}
}))(ToggleButton);

const useStyles = makeStyles(theme => ({
  dow: {
    justifyContent: "center",
    width: "100%"
  }
}));

const ToggleDays = ({ handleDaysUpdated }) => {
  const classes = useStyles();
  const [days, setDays] = useState([1, 2, 3, 4, 5]);

  //propagate state to parent component
  React.useEffect(() => {
    handleDaysUpdated(days);
  },[days]);

  return (
    <div>
      <Typography>Days of week:</Typography>
      <StyledToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={days}
        className={classes.dow}
        onChange={(event, value) => setDays(value)}
      >
        {DAYS.map((day, index) =>
          <StyledToggle key={day.key + index} value={index} aria-label={day.key}>
            {day.label}
          </StyledToggle>
        )}
      </StyledToggleButtonGroup>
    </div>
  );
};

export default ToggleDays;
