import Session from "../../utils/Session";
import { REQUEST_DEVICE_ACTIVITY } from "./actionTypes";
import { BASE_URL } from '../../utils/Network';


export const getDeviceActivity = (deviceSerialNumber, handleAPIOnError) => {
    return function(dispatch) {
  
      const action = {
        type: REQUEST_DEVICE_ACTIVITY,
        data: {
          isLoading: true,
        }
      };
      dispatch(action);
  
      const apiUrl = BASE_URL + '/api/v1/owner/getDeviceActivity';
      const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'auth-token': Session.getToken(),
        'deviceSerialNumber': deviceSerialNumber
      };
  
      return fetch(apiUrl, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: headers,
        // body data type must match "Content-Type" header
      }).then(response => response.json())
        .then(data => {
          if (data.error) {
            action.data.activityList = [];
            handleAPIOnError(data.error)
          } else {
            //success

            data.sort(function(a,b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              const dateA = parseInt(a.date);
              const dateB = parseInt(b.date)
              return new Date(dateB) - new Date(dateA);
            });
            action.data.activityList = data;
            
            console.log(data)
          }
  
          action.data.isLoading = false;
          dispatch(action);
        })
        .catch( (err) => {
          //hadle error
          handleAPIOnError(err)
          action.data.activityList = [];
          action.data.isLoading = false;
          dispatch(action);
      });
    }
  };