import Session from "../../utils/Session";
import { REQUEST_DEVICES, SEND_DEVICE_ACTION } from "./actionTypes";
import { BASE_URL } from '../../utils/Network';


export const getOwnerDevices = (handleAPIOnError) => {
  return function(dispatch) {

    const action = {
      type: REQUEST_DEVICES,
      data: {
        isLoading: true,
      }
    };
    dispatch(action);

    const apiUrl = BASE_URL + '/api/v1/owner/getDevices';
    const headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'auth-token': Session.getToken()
    };

    return fetch(apiUrl, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      // body data type must match "Content-Type" header
    }).then(response => response.json())
      .then(data => {

        if (data.error) {
          action.data.devicesList = [];
          handleAPIOnError(data.error)
        } else {
          //success
          action.data.devicesList = data;
        }

        action.data.isLoading = false;
        dispatch(action);
      })
      .catch( (err) => {
        //hadle error
        handleAPIOnError(err)
        action.data.devicesList = [];
        action.data.isLoading = false;
        dispatch(action);
    });
  }
};



export const sendDeviceAction = (device, handleAPISuccess, handleAPIOnError) => {
  return function(dispatch) {

    const action = {
      type: SEND_DEVICE_ACTION,
      data: {
        deviceSerialLoading: device.deviceSerialNumber,
      }
    };
    dispatch(action);

    const apiUrl = BASE_URL + '/api/v1/owner/device/action';
    const headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'auth-token': Session.getToken(),
      'deviceSerialNumber': device.deviceSerialNumber, 
    };

    const body = JSON.stringify({ actionUID : 'OPEN' });

    return fetch(apiUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      body: body
      // body data type must match "Content-Type" header
    }).then(response => response.json())
      .then(data => {

        console.log('data', data)
        if (data.error) {
          handleAPIOnError(data.error);
        } else {
          //success
          handleAPISuccess(data);
        }

        action.data.deviceSerialLoading = '';
        dispatch(action);
      })
      .catch( (err) => {
        //hadle error
        handleAPIOnError(err);
        action.data.deviceSerialLoading = '';
        dispatch(action);
    });
  }
};