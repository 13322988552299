// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { SEND_ACTION } from "./actionTypes";
// CONSTANTS
import { NAME } from "./constants";

const initialState = {
  isLoading: false,
  error: null,
};

const keyAccessReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === SEND_ACTION) {
    state = set(state, "isLoading", data.isLoading || false);
    state = set(state, "error", data.error || null);
  }

  return state;
};

export default keyAccessReducer;