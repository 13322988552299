import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  gridCard: {
    borderRadius: 1,
    margin: "2px",
    padding: "10px!important"
  },
  cardContent: {
    textTransform: "none",
    color: "#424143",
    fontSize: "10pt",
    marginLeft: "10px"
  },
  cardTitle: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "12pt",
    marginLeft: 10,
    marginBottom: 10
  },
  cardItemTitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "11pt",
    marginLeft: "10px"
  },
  itemText: {
    color: "#000000",
    fontSize: "10pt",
    marginLeft: "10px"
  },
  centerGrid: {
    alignSelf: "center"
  },
  spinnerStyle: {
    color: theme.palette.spinner.color
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 30,
    marginTop: 5,
    marginBottom: 5
  },
  actionButton: {
    textTransform: "capitalize!important",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 30,
    marginRight: 5,
    width: "80%",
    height: 30,
    backgroundColor: theme.palette.button.background,
    color: theme.palette.button.text,
    fontSize: 12,
    fontWeight: "bold",
    "&:hover": {
      background: theme.palette.button.text,
      color: "#ffffff"
    }
  },
  bottomButtonsContainer: {
    marginTop: 5
  },
  buttonContainer: {
    alignSelf: "center",
    alignItems: "center",
    marginLeft: 10
  },
  iconButton: {
    height: 30,
    width: 30
  }
}));

const DeviceItem = props => {
  var { device, isDeviceLoading, history } = props;
  var { handleItemClick, handleOpenDoor, handleKeysClick, handleActivityClick } = props;
  const classes = useStyles();

  const serial = device.deviceSerialNumber;
  const deviceName = device.deviceName
    ? device.deviceName.toUpperCase()
    : device.deviceModel + " " + serial.substr(serial.length - 4).toUpperCase();

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item xs={11} sm={10}>
        <Card
          className={classes.gridCard}
          onClick={e => handleItemClick(e, device)}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Grid container justiy="center">
                <Grid item xs={12} sm={12} className={classes.centerGrid}>
                  <Typography className={classes.cardTitle} component="h2">
                    {deviceName}
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid container justiy="center">
                <Grid item xs={4} sm={3} className={classes.centerGrid}>
                  <Typography className={classes.cardItemTitle} component="h2">
                    S/N:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={9} className={classes.centerGrid}>
                  <Typography className={classes.itemText} component="h2">
                    {device.deviceSerialNumber.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justiy="center">
                <Grid item xs={4} sm={3} className={classes.centerGrid}>
                  <Typography className={classes.cardItemTitle} component="h2">
                    Model:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={9} className={classes.centerGrid}>
                  <Typography className={classes.itemText} component="h2">
                    {device.deviceModel.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                className={classes.bottomButtonsContainer}
              >
                <Grid item xs={2} sm={1} className={classes.buttonContainer}>
                  <IconButton
                    className={classes.iconButton}
                    aria-label="keys"
                    onClick={e => handleKeysClick(e, device, history)}
                  >
                    <img
                      alt={""}
                      src={
                        require("../../assets/deviceItem/keychain.svg").default
                      }
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={1} className={classes.buttonContainer}>
                  <IconButton
                    className={classes.iconButton}
                    aria-label="delete"
                    onClick={e => handleActivityClick(e, device, history)}
                  >
                    <img
                      alt={""}
                      src={
                        require("../../assets/deviceItem/activity.svg").default
                      }
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2}>
                  {isDeviceLoading
                    ? <div className={classes.spinnerContainer}>
                        <CircularProgress className={classes.spinnerStyle} />
                      </div>
                    : <Button
                        className={classes.actionButton}
                        onClick={e => handleOpenDoor(e, device)}
                      >
                        Open
                      </Button>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

class DeviceItemListView extends Component {
  render() {
    const { history, deviceSerialLoading, list } = this.props;
    const { handleItemClick, handleOpenDoor, handleKeysClick, handleActivityClick } = this.props;

    return (
      <div>
        {list === undefined || list.length <= 0
          ? <div />
          : list.map((value, index) =>
              <DeviceItem
                style={{ padding: "0!important" }}
                key={`item-${index}`}
                index={index}
                handleItemClick={handleItemClick}
                handleOpenDoor={handleOpenDoor}
                handleKeysClick={handleKeysClick}
                handleActivityClick={handleActivityClick}
                history={history}
                device={value}
                isDeviceLoading={ value && (
                  deviceSerialLoading === value.deviceSerialNumber)
                }
              />
            )}
      </div>
    );
  }
}

export default DeviceItemListView;
