// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { REQUEST_DEMO } from "./actionTypes";

const initialState = {
  isLoading: false,
  error: null, 
};

const homeReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === REQUEST_DEMO) {
    state = set(state, "isLoading", data.isLoading || false);
    state = set(state, "error", data.error || null);
  }

  return state;
};

export default homeReducer;