import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Redirect } from "react-router-dom";

import DashboardDevices from "../../containers/DashboardDevices";
import DashboardDeviceKeys from "../../containers/DashboardDeviceKeys";
import DashboardCreateAccess from "../../containers/DashboardCreateAccess";
import DashboardActivity from "../../containers/DashboardActivity";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f9fafd",
    display: "flex",
    flexDirection: "row",
  },
  containerAndNav: {
    paddingLeft: 260,
    width: "100%",
    overflowY: "auto"
  },
  containerNoNav: {
    width: "100%",
    overflowY: "auto"
  }
}));

export default function DashboardContainer(props) {
  const { matchesMD } = props

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={
          !matchesMD? classes.containerNoNav : classes.containerAndNav
        }>
        <Route exact path="/dashboard/access/activity" component={DashboardActivity} />
        <Route exact path="/dashboard/access" component={DashboardDevices} />
        <Route path="/dashboard/access/key" component={DashboardDeviceKeys} />
        <Route exact path="/dashboard/access/create" component={DashboardCreateAccess} />

      </div>
    </div>
  );
}
