import React, { Component } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import { signIn, signOut } from "../../containers/Login/actions";
import { NAME } from "../../containers/Login/constants";
import { Divider } from "material-ui";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  toolbar: {
    height: 50,
    minHeight: 50,
  },
  title: {
    flexDirection: "row",
    marginRight: "auto",
    justifyContent: "flex-start",
    height: 30
  },
  loginButton: {
    borderRadius: 15,
    textTransform: "none",
    width: 100,
    fontWeight: 900,
    color: "#0760a4",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#0760a4",
      color: "#ffffff"
    }
  },
  registerButton: {
    borderRadius: 15,
    textTransform: "none",
    width: 100,
    fontWeight: 900,
    marginRight: "20px",
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
    "&:hover": {
      borderStyle: "solid",
      borderColor: theme.palette.secondary.main,
      backgroundColor: "#ffffff",
      color: theme.palette.secondary.main
    }
  },
  sectionsButtons: {
    textTransform: "none",
    fontWeight: 900,
    marginRight: "20px",
    color: "#ffffff",
    border: "none",
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  account: {
    width: 20
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none!important"
    }
  },
  buttonsTopbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none!important",

    }
  }

}));

const ButtonAppBarComponent = props => {
  const redirectToHome = props.redirectToHome;
  const redirectToDashboard = props.redirectToDashboard;

  const redirectToAccount = props.redirectToAccount;
  const sectionButtons = props.sectionButtons;
  const handleSectionEvent = props.handleSectionEvent;

  const handleDrawerOpen = props.handleDrawerOpen;

  const properties = props.props;


  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    properties.signOut();
  };

  return (
    <AppBar position="fixed" elevation={1} className={classes.toolbar}>
      <Toolbar className={classes.toolbar}>
        {handleDrawerOpen !== undefined
          ? <IconButton
              onClick={handleDrawerOpen}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          : <div />}

        <img
          alt=""
          src={require("../../assets/topbar/logo.svg").default}
          className={classes.title}
          onClick={() => {
            redirectToHome(properties);
          }}
        />

        <div className={classes.buttonsTopbar}>
        {sectionButtons
          ? sectionButtons.map((item, index) =>
              <Button
                variant="outlined"
                size="small"
                className={classes.sectionsButtons}
                onClick={() => {
                  handleSectionEvent(item.sectionId);
                }}
              >
                {item.buttonTitle}
              </Button>
            )
          : null}
        </div>
        <div>
              <img
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.account}
                alt=""
                src={require("../../assets/topbar/ic_account.svg").default}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    redirectToAccount(properties);
                  }}
                >
                  Mi Cuenta
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToDashboard(properties);
                  }}
                >
                  Mis accesos
                </MenuItem>
                <MenuItem onClick={handleSignOut}>Cerrar Sesión</MenuItem>
              </Menu>
            </div>
      </Toolbar>
    </AppBar>
  );
};

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.redirectToLogin.bind(this);
    this.redirectToDashboard.bind(this);
    this.redirectToAccount.bind(this);
  }

  redirectToLogin(props) {
    let path = "/login";
    props.props.history.push(path);
  }

  redirectToDashboard(props) {
    let path = "/dashboard/access";
    props.props.history.push(path);
  }

  redirectToAccount(props) {
    // let path = "/dashboard/account";
    let path = "/dashboard/account";
    props.props.history.push(path);
  }

  render() {
    return (
      <ButtonAppBarComponent
        props={this.props}
        redirectToLogin={this.redirectToLogin}
        redirectToDashboard={this.redirectToDashboard}
        redirectToAccount={this.redirectToAccount}
        handleDrawerOpen={this.props.handleDrawerOpen}
        sectionButtons={this.props.sectionButtons}
        handleSectionEvent={this.props.handleSectionEvent}
      />
    );
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  // console.log(state);
  return {
    isLoading: state[NAME].isLoading,
    authError: state[NAME].error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: creds => dispatch(signIn(creds)),
    signOut: () => dispatch(signOut()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
