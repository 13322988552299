import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { customTheme } from "../../providers/theme/customTheme";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    marginBottom: 10
  }
}));

export const pickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: customTheme.palette.button.text
    }
  }
});

// this kint of access never expires. User needs to removed manually
export default function GuestAccess({ handleGuestAccessUpdated }) {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  React.useEffect(
    () => {
      const startDateUTC = buildTimeStampUTC(startDate);
      const endDateUTC = buildTimeStampUTC(endDate);

      if (startDateUTC > endDateUTC) {
        alert("Wrong dates selected.");
        setEndDate(startDate);
      } else {
        handleGuestAccessUpdated({
          type: "GUEST",
          fromDate: startDateUTC,
          toDate: endDateUTC
        });
      }
    },
    [startDate, endDate]
  );

  /**
   * Converts to UTC Time.
   * */
  const buildTimeStampUTC = dateTime => {
    return Math.floor(dateTime.getTime() / 1000);
  };

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={pickerTheme}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disablePast="true"
              format="dd/MM/yyyy"
              margin="normal"
              id="time-picker"
              label="From Date:"
              value={startDate}
              onChange={setStartDate}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />

            <KeyboardDatePicker
              disablePast="true"
              format="dd/MM/yyyy"
              margin="normal"
              id="time-picker"
              label="To Date:"
              value={endDate}
              onChange={setEndDate}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />
            
            
          </Grid>

          <Grid container justify="space-around">
            <KeyboardTimePicker
              margin="normal"
              disablePast="true"
              id="time-picker"
              label="From Time:"
              value={startDate}
              onChange={setStartDate}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />

            <KeyboardTimePicker
             margin="normal"
             disablePast="true"
             id="time-picker"
             label="To Time:"
             value={endDate}
             onChange={setEndDate}
             KeyboardButtonProps={{
               "aria-label": "change time"
             }}
            />
            
            
          </Grid>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}
