import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";

import Session from "../../utils/Session";
import { getDeviceAccess, removeDeviceAccess } from "./actions";
import { NAME } from "./constants";
import AccessItemListView from "../../components/items/AccessItemListView";
import DeleteAccessDialog from "../../components/modals/DeleteAccessDialog";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    height: "100vh",
    width: "100%"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  back: {
    position: "absolute",
    marginLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20px"
    }
  },
  title: {
    textTransform: "capitalize",
    color: "#2D2D2D",
    fontWeight: "bold",
    fontSize: "16pt",
    top: "50%",
    textAlign: "center",
    padding: "0px!important",
    marginBottom: 20,
    marginTop: 10,
    fontFamily: "inherit"
  },
  accessList: {
    marginTop: 30
  },
  spinnerStyle: {
    color: theme.palette.spinner.color
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 30,
    marginTop: 5,
    marginBottom: 5
  }
}));

const DashboardDeviceKeysSection = props => {
  const classes = useStyles();
  const {
    list,
    device,
    isLoading,
    handleItemClick,
    history,
    removeDeviceAccess,
    getDeviceAccess
  } = props;
  const [deleteObject, setDeleteObject] = React.useState({
    access: undefined,
    device: undefined,
    open: false
  });

  function handleBack() {
    history.goBack();
  }

  function handleOpen() {
    // setOpen(true);
    let path = "/dashboard/access/create";
    history.push(path, device);
  }

  function handleDeleteAction(e, device, access) {
    console.log("handleDeleteAction");
    e.stopPropagation();
    setDeleteObject({
      ...deleteObject,
      device: device,
      access: access,
      open: true
    });
  }

  function handleDeleteAccess(e, device, access) {
    //here we call the API to delete an access
    e.stopPropagation();
    handleCloseDeleteAccess();
    removeDeviceAccess(
      device.deviceSerialNumber,
      access,
      handleAPISuccess,
      handleAPIOnError
    );
  }

  function handleCloseDeleteAccess() {
    setDeleteObject({
      ...deleteObject,
      device: undefined,
      access: undefined,
      open: false
    });
  }

  function handleAPIOnError(error) {
    alert(error);
    Session.disposeSession();
    window.location.href = "/login";
  }

  function handleAPISuccess(data) {
    //Refresh access list
    console.log("Success", data);
    getDeviceAccess(device.deviceSerialNumber, handleAPIOnError);
  }

  const serial = device.deviceSerialNumber;
  const deviceName = device.deviceName
    ? device.deviceName.toUpperCase()
    : device.deviceModel + " " + serial.substr(serial.length - 4).toUpperCase();

  return (
    <div className={classes.container}>
      <div className={classes.items}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Fab
              aria-label="back"
              className={classes.back}
              color="secondary"
              size="small"
              onClick={() => handleBack()}
            >
              <ArrowBackIcon />
            </Fab>
            <Typography className={classes.title} gutterBottom component="h2">
              {deviceName}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {isLoading
        ? <div className={classes.spinnerContainer}>
            <CircularProgress className={classes.spinnerStyle} />
          </div>
        : <AccessItemListView
            clasName={classes.accessList}
            list={list}
            device={device}
            isLoading={isLoading}
            handleItemClick={handleItemClick}
            handleDeleteAction={handleDeleteAction}
          />}

      <Fab
        aria-label="Agregar item"
        className={classes.fab}
        color="secondary"
        onClick={() => handleOpen()}
      >
        <AddIcon />
      </Fab>
      <DeleteAccessDialog
        handleClose={handleCloseDeleteAccess}
        open={deleteObject.open}
        handleDeleteItem={handleDeleteAccess}
        device={deleteObject.device}
        access={deleteObject.access}
      />
    </div>
  );
};

class DashboardDeviceKeys extends Component {
  constructor(props) {
    super(props);
    this.handleOnLogin.bind(this);
    this.handleItemClick.bind(this);
  }

  componentDidMount() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props);
    } else {
      const device = this.props.location.state;
      console.log("device: ", device);
      this.props.getDeviceAccess(device.deviceSerialNumber, this.handleAPIOnError);
    }
  }

  componentDidUpdate() {
    if (!Session.getToken()) {
      this.handleOnLogin(this.props);
    }
  }

  handleOnLogin(props) {
    let path = "/login";
    props.history.push(path);
  }

  handleAPIOnError(error) {
    alert(error)
    Session.disposeSession();
    window.location.href = "/login";
  }

  handleItemClick(e, device, access) {
    e.stopPropagation();
  }

  render() {
    return (
      <DashboardDeviceKeysSection
        list={this.props.accessList}
        device={this.props.location.state}
        isLoading={this.props.isLoading}
        handleItemClick={this.handleItemClick}
        history={this.props.history}
        getDeviceAccess={this.props.getDeviceAccess}
        removeDeviceAccess={this.props.removeDeviceAccess}
      />
    );
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    accessList: state[NAME].accessList,
    isLoading: state[NAME].isLoading,
    error: state[NAME].error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDeviceAccess: (serialNumber, handleAPIOnError) => dispatch(getDeviceAccess(serialNumber, handleAPIOnError)),
    removeDeviceAccess: (
      serialNumber,
      access,
      handleAPISuccess,
      handleAPIOnError
    ) =>
      dispatch(
        removeDeviceAccess(
          serialNumber,
          access,
          handleAPISuccess,
          handleAPIOnError
        )
      )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  DashboardDeviceKeys
);
