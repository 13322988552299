import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    marginBottom: 10,
  },
  text: {
      textAlign: 'center'
  }
}));

// This kind of access never expires
export default function DoorBellAccess() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
        Create a QR code of this access to receive Notification in to your Cittyo Android or iOS App.
      </Typography>
    </div>
  );
}
