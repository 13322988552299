import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: "capitalize!important",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.buttonCancel.background,
    color: theme.palette.buttonCancel.text,
    fontSize: 15,
    fontWeight: "bold",
    "&:hover": {
      background: theme.palette.buttonCancel.text,
      color: "#ffffff"
    }
  }
}));

export default function CancelButton({buttonText, handleOnClick}) {
  const classes = useStyles();

  return (
    <div>
      <Button className={classes.button} onClick={handleOnClick}>
        {buttonText}
      </Button>
    </div>
  );
}
