import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import ProfileMenu from "./ProfileMenu";
import { signOut } from "../../containers/Login/actions";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    height: "calc(100vh - 50px)"
  },
  listItem: {
    marginRight: "30px",
    height: "50px",
    backgroundColor: theme.palette.secondary.dark,
    color: "#ffffff",
    "&:hover": {
      //55c9f4
      backgroundColor: theme.palette.secondary.dark,
      opacity: "1",
      backgroundSize: "cover",
      overflow: "hidden",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center"
    }
  },
  listItemSelected: {
    marginRight: "30px",
    height: "50px",
    backgroundColor: "#ffffff",
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "&:hover": {
      //55c9f4
      backgroundColor: "#ffffff",
      backgroundSize: "cover",
      overflow: "hidden",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center"
    }
  },
  image: {
    height: "20px",
    width: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    fill: "#ffffff"
  },
  menuLink: {
    height: "60px",
    textDecoration: "none",
    color: "#404042"
  },
  dividerElements: {
    background: "white",
    height: "1px",
    marginLeft: "50px",
    marginRight: "50px",
    marginBottom: "30px",
    marginTop: "40px"
  },
  text: {
    fontSize: "10pt",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  logOutElement: {
    marginTop: "50px",
    textDecoration: "none",
    color: "#404042",
    "&:hover": {
      //55c9f4
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer"
    }
  }
}));

const MenuContentSection = props => {
  const { user, history } = props.props;
  const classes = useStyles();
  const listElements = [
    {
      title: "My Devices",
      route: "/dashboard/access",
      asset: require("../../assets/topbar/ic_access.svg").default
    },
    // {
    //   title: "Activity",
    //   route: "/dashboard/activity",
    //   asset: require("../../assets/topbar/ic_activity.svg").default
    // }
  ];
  const secondMenu = [];

  function logOutClickListener() {
    props.props.signOut();
    let path = "/login";
    history.push(path);
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <ProfileMenu user={user} />
        <List>
          {listElements.map((item, index) =>
            <Link
              to={item.route}
              key={"MENU_ITEM_" + index}
              className={classes.menuLink}
            >
              <ListItem
                button
                className={classes.listItem}
                onClick={() => props.refreshItem(index)}
              >
                <img className={classes.image} src={item.asset} alt="" />
                <p className={classes.text}>
                  {item.title}
                </p>
              </ListItem>
            </Link>
          )}
        </List>
        <Divider className={classes.dividerElements} />
        <List>
          {secondMenu.map((item, index) =>
            <Link
              to={item.route}
              key={"MENU_ITEM_BASE_" + index}
              className={classes.menuLink}
            >
              <ListItem
                button
                className={classes.listItem}
                onClick={() => props.refreshItem2(index)}
              >
                <img className={classes.image} src={item.asset} alt="" />
                <p className={classes.text}>
                  {item.title}
                </p>
              </ListItem>
            </Link>
          )}
        </List>
        <div className={classes.logOutElement}>
          <ListItem onClick={logOutClickListener} className={classes.listItem}>
            <Icon className={classes.icon}> </Icon>
            <p className={classes.text}>
              {"Logout"}
            </p>
          </ListItem>
        </div>
      </div>
    </React.Fragment>
  );
};

class MenuContent extends Component {
  state = {
    item: 0,
    item2: -1
  };

  refreshItem = res => {
    this.setState({ item: res });
    this.setState({ item2: -1 });
  };

  refreshItem2 = res => {
    this.setState({ item: -1 });
    this.setState({ item2: res });
  };

  render() {
    const { item } = this.state;
    const { item2 } = this.state;

    return (
      <MenuContentSection
        props={this.props}
        selectedItem={item}
        refreshItem={this.refreshItem}
        selectedItem2={item2}
        refreshItem2={this.refreshItem2}
        history={this.props.history}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(signOut())
  };
}

//propiedades a guardar en el store
export default connect(null, mapDispatchToProps)(MenuContent);
